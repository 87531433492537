import React, { useContext, Fragment } from "react"
import { NavLink, withRouter } from "react-router-dom"
// import { useTranslation } from "react-i18next"
import { useClickOutside } from "use-events"
import classNames from "classnames"

import { AppProvider } from "../../AppProvider"
import { ReactComponent as CompanyIcon } from "./Espertech-no-name.svg"
import Jobs from "./Jobs"
import AboutUs from "./AboutUs"
import LangSelect from "../footer/LangSelect"

const Nav = ({ location: { pathname } }) => {
  // const { t } = useTranslation()

  const {
    jobsVisibility,
    aboutUsVisibility,
    setJobsVisibility,
    setAboutUsVisibility,
    // navModuleChanger,
    jobsRef,
    aboutUsRef,
  } = useContext(AppProvider)

  useClickOutside([jobsRef, aboutUsRef], () => {
    if (jobsVisibility) setJobsVisibility(false)
    if (aboutUsVisibility) setAboutUsVisibility(false)
  })

  return (
    <Fragment>
      <nav>
        <ul className="nav">
          <li>
            {pathname !== "/" && (
              <NavLink className="nav-home" to="/" exact>
                <CompanyIcon className="companyLogo" />
              </NavLink>
            )}
          </li>
          <div className="nav-links">
            {/* <li onClick={() => navModuleChanger("TOGGLE_ABOUT_US")}>
                            {t("aboutUs")}
                        </li>
            <li onClick={() => navModuleChanger("TOGGLE_JOBS")}>{t("jobs")}</li> */}
            <LangSelect />
          </div>
        </ul>
        <Jobs />
        <AboutUs />
      </nav>
      <div
        className={classNames({
          "background-dim": true,
          "background-dim-open": jobsVisibility || aboutUsVisibility,
        })}
      />
    </Fragment>
  )
}

export default withRouter(Nav)
