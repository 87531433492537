import React, { useContext, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
// import { formatDistanceToNow } from "date-fns"
import classNames from "classnames"
import { FlagIcon } from "react-flag-kit"

import { ReactComponent as CloseButton } from "../shared/x.svg"
import { AppProvider } from "../../AppProvider"

const JOBSOID_URL = "https://seekerscapital.jobsoid.com/api/v1/jobs/"

const Jobs = () => {
  const { t } = useTranslation()

  const { jobsRef, jobsVisibility, navModuleChanger } = useContext(AppProvider)

  // const [jobData, setJobData] = useState([])

  // const jobIds = {
  //     devOps: 20270,
  //     IT: 19301
  // }

  // const fetchJobData = async jobId => {
  //     let response = await fetch(
  //         `https://seekerscapital.jobsoid.com/api/v1/jobs/${jobId}`
  //     )
  //     let data = await response.json()
  //     const { id, title, location, postedDate, hostedUrl } = data
  //     const timeDiff = distanceInWordsToNow(postedDate)
  //     setJobData(currState => [
  //         ...currState,
  //         {
  //             id,
  //             title,
  //             location,
  //             timeDiff,
  //             hostedUrl
  //         }
  //     ])
  // }

  // useEffect(() => {
  //     Object.values(jobIds).map(id => fetchJobData(id))
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const [fetching, setFetching] = useState(true)
  const [jobs, setJobs] = useState()

  useEffect(() => {
    setFetching(true)

    const fetchData = async () => {
      try {
        const result = await fetch(JOBSOID_URL)
        const json = await result.json()
        setJobs(json)
        setFetching(false)
      } catch (err) {
        console.log(err)
      }
    }

    fetchData()
  }, [])

  return (
    !fetching && (
      <aside
        className={classNames({
          jobs: true,
          "jobs-open": jobsVisibility,
        })}
        ref={jobsRef}
      >
        <div className="jobs-header">
          <h1 className="jobs-title">{t("jobs")}</h1>
          <div
            className="close-btn"
            onClick={() => navModuleChanger("CLOSE_JOBS")}
          >
            <CloseButton />
          </div>
        </div>
        <ul>
          {jobs.map(
            ({
              id,
              title,
              location: { title: countryCode, city },
              postedDate,
              hostedUrl,
            }) => {
              return (
                <li key={id}>
                  <a
                    href={hostedUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="job"
                  >
                    <div className="job-info-container">
                      <h1>{title}</h1>
                      <div className="job-detail">
                        <div className="location-time">
                          <FlagIcon
                            className="flag"
                            code={countryCode}
                            size={20}
                          />
                          <span className="location">{city}</span>
                          {/* <span className="job-time">
                            {formatDistanceToNow(new Date(postedDate))} ago
                          </span> */}
                        </div>
                      </div>
                    </div>
                    <button>{t("apply")}</button>
                  </a>
                </li>
              )
            }
          )}
        </ul>
      </aside>
    )
  )
}

export default Jobs
