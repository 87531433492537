import React, { Fragment, lazy, Suspense } from "react"
import { Helmet } from "react-helmet"

import Section1 from "../section-1/Section1"

const Video = lazy(() => import("../background-video/Video"))

const Homepage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Espertech - Your Trusted IT Partners</title>
                <meta
                    name="description"
                    content="Espertech helps you take control of your IT, offers professional expertise and is supported by strong vendor
                    partners."
                />
            </Helmet>
            <Suspense
                fallback={
                    <img
                        className="background-image"
                        src="/assets/lazy-image.png"
                        alt="Lazy background video screenshot"
                    />
                }
            >
                <Video />
            </Suspense>
            <Section1 />
        </Fragment>
    )
}

export default Homepage
