import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useClickOutside } from "use-events"
import classNames from "classnames"
import Fade from "react-reveal/Fade"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

import { AppProvider } from "../../AppProvider"
import Form from "./Form"

const Section1 = () => {
    const { t } = useTranslation()

    const {
        formVisibility,
        setFormVisibility,
        toggleFormVisibility,
        formRef
    } = useContext(AppProvider)

    useClickOutside([formRef], () => {
        if (formVisibility) setFormVisibility(false)
    })

    return (
        <section className="section1">
            <div
                className={classNames({
                    "background-dim": true,
                    "background-dim-open": formVisibility
                })}
            />
            <img
                className="company-logo"
                src="/assets/Espertech-logo.svg"
                alt="Espertech logo"
            />
            <Fade bottom>
                <h1 className="tagline">
                    <span>{t("yourTrusted")}</span>
                    <span>{t("itPartners")}</span>
                </h1>
            </Fade>
            <h3 className="sub-tagline">{t("tagline")}</h3>
            <button onClick={toggleFormVisibility}>
                {t("requestConsultation")}{" "}
                <span className="arrow-right">
                    <FontAwesomeIcon icon={faAngleRight} size="sm" />
                </span>
            </button>
            <Form />
        </section>
    )
}

export default Section1
