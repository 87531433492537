import React, { Fragment } from "react"

import Cookie from "./cookie/Cookie"
import Nav from "./nav/Nav"
import Footer from "./footer/Footer"

const Layout = ({ children }) => {
    return (
        <Fragment>
            <Cookie />
            <Nav />
            {children}
            <Footer />
        </Fragment>
    )
}

export default Layout
