import React from "react"
import { useTranslation } from "react-i18next"
import Fade from "react-reveal/Fade"

const PrivacyText = () => {
    const { t } = useTranslation()

    return (
        <div className="privacyPolicy">
            <Fade>
                <h1 className="large-heading">{t("privacyPolicy")}</h1>
            </Fade>
            <p className="paragraph">
                This Privacy Policy applies to the use of the website and to all
                the services provided by Esper Technologies Limited and its
                worldwide affiliated companies (“ETL”). ETL is a company with
                headquarters in Hong Kong.
            </p>
            <p className="paragraph">
                By using ETL’s website or services, you consent to the
                collection and processing of information relating to you in
                accordance with this Privacy Policy.
            </p>
            <h3 className="subheading">
                What is the purpose of this Privacy Policy?
            </h3>
            <p className="paragraph">
                This Privacy Policy details what personal information we may
                collect, process and store about our website users, our clients
                and our potential clients and why we collect, use and disclose
                the information that you share with us through ETL’s website,
                when you use ETL’s services or when you otherwise interact with
                us, in accordance with the Personal Information (Privacy)
                Ordinance (Cap. 486) and, where applicable, the Regulation (EU)
                2016/679 (General Data Protection Regulation) (“GDPR”).
                “Personal Information” means any information relating to an
                identified or identifiable natural person.
            </p>
            <h3 className="subheading">
                Which information is collected and how do we process your
                Personal Information?
            </h3>
            <p className="paragraph">
                In our capacity as a service provider, we will receive, store,
                and/or process Personal Information that you provide when you
                use ETL’s website, request a consultation, use ETL’s services or
                otherwise interact with ETL.
            </p>
            <p className="paragraph">
                The provision of your Personal Information is necessary for us
                to provide our services to you.
            </p>
            <p className="paragraph">
                The information that we collect from you is used for the
                purposes identified below:
            </p>
            <ul className="list">
                <li>processing orders</li>
                <li>
                    delivering and providing the requested products and services
                </li>
                <li>
                    communicating with you about your request for or use of
                    ETL’s services
                </li>
                <li>complying with contractual obligations related thereto</li>
                <li>managing transactions</li>
                <li>reporting</li>
                <li>invoicing</li>
                <li>renewals</li>
                <li>maintaining and supporting our products and services</li>
                <li>
                    other operations related to providing services to clients
                </li>
                <li>
                    tax and other mandatory reporting requirements under
                    applicable law
                </li>
                <li>
                    pursuing or enforcing our legal rights related to our
                    business, services and/or website and defend against claims
                    made against us
                </li>
            </ul>
            <h3 className="subheading">What data do we collect?</h3>
            <span className="underline">Clients</span>
            <p className="paragraph">
                ETL collects the following information on the basis of providing
                contractual services to its clients and their employees or
                taking steps to enter into a service contract with its clients:
            </p>
            <p>Data Fields – Personal Information</p>
            <ul className="list">
                <li>
                    FIRST NAME, LAST NAME, EMAIL, WORK EMAIL, PERSONAL ADDRESS,
                    WORK ADDRESS, HOME IP ADDRESS, LAN IP ADDRESS, WAN PHONE
                    NUMBER, WORK PHONE NUMBER, HOME MOBILE PHONE NUMBER, WORK
                    MOBILE PHONE NUMBER, HOME MOBILE PHONE IMEI, WORK MOBILE
                    PHONE IMEI, HOME ONLINE FORUM/WEBSITE/SOFTWARE ACCOUNT
                    USERNAME(S)
                </li>
            </ul>
            <p className="paragraph">
                ETL also collects the following data and Personal Information
                about clients and their employees while providing services to
                clients: hardware diagnosis, network identification, hardware
                identification, hardware owner name or username.
            </p>
            <span className="underline">
                Potential clients and website users
            </span>
            <p className="paragraph">
                ETL collects the following data and Personal Information on the
                basis of consent when website users and/or potential clients
                request a consultation on ETL’s website or otherwise communicate
                with ETL:
            </p>
            <ul className="list">
                <li>
                    FIRST NAME, LAST NAME, COMPANY NAME, WORK EMAIL, PHONE
                    NUMBER AND ANY OTHER DATA PROVIDED IN MESSAGE FIELD OR
                    COMMUNICATIONS WITH ETL
                </li>
            </ul>
            <span className="underline">Website users</span>
            <p>
                ETL collects the following data and Personal Information on the
                basis of consent during the period website users use ETL’s
                website:
            </p>
            <ul className="list">
                <li>IP ADDRESS, BROWSER TYPE AND OTHER DATA THROUGH COOKIES</li>
            </ul>
            <h3 className="subheading">How do we collect your data?</h3>
            <p className="paragraph">
                You directly provide us with most of the data that we collect.
                We collect data and process data when you:
            </p>
            <ul className="list">
                <li>
                    contact us about and/or place an order for any of our
                    products or services
                </li>
                <li>request a consultation</li>
                <li>use or view our website via your browser’s cookies</li>
            </ul>
            <p className="paragraph">
                We also collect data and process data when we provide our
                services to you.
            </p>
            <h3 className="subheading">How do we share your data?</h3>
            <span className="underline">Within the group</span>
            <p className="paragraph">
                ETL works in close collaboration with its parent, Seekers
                Capital Group Limited, and other affiliated companies within the
                group, in order to develop the products and provide the
                services. As such, ETL may process and share your Personal
                Information with Seekers Capital Group Limited and other
                affiliated companies within the group.
            </p>
            <span className="underline">Sharing for legal purposes</span>
            <p className="paragraph">
                We may disclose any information, including your Personal
                Information and any other information or data collected, stored
                or processed on our servers, if required to do so by law or in
                the good-faith belief that such action is necessary to (i)
                comply with any law, regulation, legal process or lawful
                governmental requests, (ii) protect the rights or property of
                ETL or our customers, including the enforcement of our
                agreements or policies governing your use of our website or the
                services, and (iii) act on a good faith belief that such access
                or disclosure is necessary to protect the personal safety of
                ETL’s employees, customers, or the public.
            </p>
            <span className="underline">
                Change of ownership or restructuring
            </span>
            <p className="paragraph">
                In the event of a proposed or actual business transaction,
                corporate restructuring, sale or merger of ETL, or involving
                some or all of our business, assets or equity, we may disclose
                and/or transfer information we have collected to those involved
                in assessing, planning and completing the sale (including the
                buyer and its employees, agents and representatives).
            </p>
            <span className="underline">Third party providers</span>
            <p className="paragraph">
                Where we transfer your Personal Information to third parties who
                help provide our products and services, we will obtain
                contractual commitments from them to protect your Personal
                Information and these third-party providers will be listed in
                this Privacy Policy, where applicable.
            </p>
            <p className="paragraph">
                We do not sell or provide Personal Information about our users
                or clients to any third party for marketing purposes.
            </p>
            <h3 className="subheading">How do we keep your data secure?</h3>
            <p className="paragraph">
                ETL takes administrative and technical measures to protect your
                Personal Information from unauthorized or accidental access,
                processing, erasure, loss or use.
            </p>
            <p className="paragraph">
                We ensure that only the personal, related entities and agents
                that are strictly necessary to the provision of the services
                have access to your Personal Information. All recipients are
                subject to strict confidentiality obligations.
            </p>
            <p className="paragraph">
                While we do use care to protect your information, no data
                transmission over the Internet or method of electronic storage
                can be guaranteed to be 100% secure. As a result, we cannot
                guarantee absolute security.
            </p>
            <h3 className="subheading">
                How do we store your data and international transfers
            </h3>
            <p className="paragraph">
                We will transfer data and Personal Information internally to
                related entities, or store data and Personal Information in data
                centers and servers around the world, which may be located in
                countries where data protection standards may differ from those
                in the country where you reside. Some countries are classed by
                the European Commission's adequacy decisions as offering
                adequate levels of protection for information transfers, but
                other countries may not offer the same level of protection. In
                certain circumstances, courts, law enforcement agencies,
                regulatory agencies or security authorities in third countries
                may be entitled to access your Personal Information.
            </p>
            <p className="paragraph">
                We will take reasonable steps and security measures to protect
                your Personal Information from unauthorized or unlawful
                processing and against accidental loss, destruction or damage,
                including applying generally accepted industry standards,
                encryption, access controls and security breach protocols.
            </p>
            <p className="paragraph">
                By using ETL’s website and our services and by providing
                information relating to you, you understand and agree that we
                may transfer your Personal Information globally.
            </p>
            <h3 className="subheading">How long will we keep your data?</h3>
            <p className="paragraph">
                We store your Personal Information as long as necessary to
                provide our services to you. We will retain your Personal
                Information and all communications between you and us for the
                period necessary to comply with applicable legal and regulatory
                obligations and recordkeeping requirements, or to address any
                complaints related to your Personal Information. When your
                Personal Information is no longer required, it will be securely
                destroyed.
            </p>
            <h3 className="subheading">
                Your Rights in connection with Personal Information
            </h3>
            <span className="underline">
                Your right to access your Personal Information
            </span>
            <p className="paragraph">
                You have a right to access your Personal Information. You may
                obtain a copy of your Personal Information upon request.
            </p>
            <span className="underline">Your right to rectification</span>
            <p className="paragraph">
                You may request that we correct any inaccurate Personal
                Information or that we complete any incomplete Personal
                Information.
            </p>
            <span className="underline">Your right to erasure</span>
            <p className="paragraph">
                Unless we have an overriding legitimate ground for continued
                processing, you have the right to request the deletion of your
                Personal Information, under certain conditions.
            </p>
            <span className="underline">Your right to restrict processing</span>
            <p className="paragraph">
                You have the right to request that we restrict the processing of
                your Personal Information, under certain conditions.
            </p>
            <span className="underline">
                Your right to object to processing
            </span>
            <p className="paragraph">
                You have the right to object to the processing of your Personal
                Information, under certain conditions.
            </p>
            <span className="underline">Your right to data portability</span>
            <p className="paragraph">
                You have the right to request that we transfer the Personal
                Information that we have collected to another organization, or
                directly to you, under certain conditions.
            </p>
            <p className="paragraph">
                If you would like to exercise any of these rights, please notify
                us in accordance with the “Contact Details at ETL” section
                below. We will ensure to comply with your requests free of
                charge, as soon as practicable and consistently with applicable
                law. However, in the event your requests are manifestly
                unfounded or excessive, we may charge an administrative fee or
                refuse to meet your request.
            </p>
            <p className="paragraph">
                Please note that, in order to protect your Personal Information,
                we may need to verify your identity before fulfilling your
                request.
            </p>
            <h3 className="subheading">Cookies</h3>
            <p className="paragraph">
                When you use ETL’s website, we may collect information from you
                automatically through cookies or other similar technologies.
                Cookies are small text files that are stored on users’ computer
                hard drive or device when they visit a website.
            </p>
            <p className="paragraph">
                There are a number of different types of cookies. ETL’s website
                uses functionality cookies in order to improve your experience
                on the website (including to understand how you use ETL’s
                website and remembering your previously selected preferences),
                cookies to provide us with general analytics and gather
                demographic information in a non-identifiable form, and security
                cookies in order to keep data secure.
            </p>
            <p className="paragraph">
                ETL’s website also uses third-party cookies such as Google
                Analytics, a service which transmits website traffic data to
                Google servers. Google Analytics does not identify individual
                users or associate your IP address with any other data held by
                Google. We use reports provided by Google Analytics to help us
                understand website traffic and webpage usage.
            </p>
            <p className="paragraph">
                By using ETL’s website, you consent to the processing of data
                about you by Google in the manner described in Google's privacy
                policy. You can opt out of Google Analytics if you disable or
                refuse the cookie, disable JavaScript, or use the opt-out
                service provided by Google-external site.
            </p>
            <p className="paragraph">
                You can disable and/or delete cookies on your device by
                indicating this in the preferences or options menu in your
                browser. However, doing so may limit your ability to use ETL’s
                website.
            </p>
            <h3 className="subheading">Children</h3>
            <p className="paragraph">
                Our website and services are not targeted to individuals under
                the age of 18.
            </p>
            <h3 className="subheading">Complaints</h3>
            <p className="paragraph">
                If you are in the European Union, you have the right to raise
                your issues or lodge a complaint with the relevant data privacy
                authority in your jurisdiction.
            </p>
            <h3 className="subheading">Changes to Privacy Policy</h3>
            <p className="paragraph">
                Changes may be necessary to this policy to reflect legal or data
                processing developments. The revised Privacy Policy will be
                posted on ETL’s website. A notice will be posted on our homepage
                for 30 days whenever this Privacy Policy is changed in a
                material way, and the date of last update will be indicated at
                the top of the Privacy Policy.
            </p>
            <h3 className="subheading">Contact Details at ETL</h3>
            <p className="paragraph">
                For all queries, complaints or to review your rights and
                information we have on file, please contact us at{" "}
                <a href="mailto: privacy@espertechnologies.com.">
                    privacy@espertechnologies.com.
                </a>
            </p>
        </div>
    )
}

export default PrivacyText
