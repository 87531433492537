import React, { useContext } from "react"
import { useClickOutside } from "use-events"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { FlagIcon } from "react-flag-kit"

import { AppProvider } from "../../AppProvider"

const LangSelect = () => {
    const { t } = useTranslation()

    const {
        langMenu,
        setLangMenu,
        toggleLangMenu,
        changeLanguage,
        languageRef,
        langMenuRef
    } = useContext(AppProvider)

    useClickOutside([languageRef, langMenuRef], () =>
        langMenu ? setLangMenu(false) : ""
    )

    return (
        <div className="languageSelect" onClick={toggleLangMenu}>
            <div className="language" ref={languageRef}>
                <FlagIcon className="flag" code={t("flag")} size={20} />
            </div>
            <ul
                className={classNames({
                    languageMenu: true,
                    "languageMenu-open": langMenu
                })}
                ref={langMenuRef}
            >
                <h1 className="langHeader">LANGUAGES</h1>
                <li className="lang-option">
                    <button onClick={() => changeLanguage("en")}>
                        English
                    </button>
                </li>
                <li className="lang-option">
                    <button onClick={() => changeLanguage("cn")}>
                        繁體中文
                    </button>
                </li>
            </ul>
        </div>
    )
}

export default LangSelect
