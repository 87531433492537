import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import Recaptcha from "react-recaptcha"

import { AppProvider } from "../../AppProvider"
import { ReactComponent as CloseButton } from "../shared/x.svg"

const Form = () => {
    const { t } = useTranslation()

    const { formRef, formVisibility, closeForm } = useContext(AppProvider)

    const [verified, setVerified] = useState(false)

    const verifyCallback = response => response && setVerified(true)

    const handleSubmit = () =>
        !verified && alert("Please complete the reCAPTCHA.")

    return (
        <form
            className={classNames({
                form: true,
                "form-open": formVisibility
            })}
            ref={formRef}
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-recaptcha="true"
            onSubmit={handleSubmit}
        >
            <input type="hidden" name="form-name" value="contact" />
            <div className="form-header">
                <div className="form-heading">
                    <h1>{t("requestConsultation")}</h1>
                    <div className="close-btn" onClick={closeForm}>
                        <CloseButton />
                    </div>
                </div>
                <h3>
                    If you have any questions, just reach out and we’ll respond
                    as soon as we can.
                </h3>
            </div>
            <label htmlFor="firstname">
                First Name
                <input
                    type="text"
                    name="firstname"
                    placeholder="Enter your first name..."
                    required
                />
            </label>
            <label htmlFor="lastname">
                Last Name
                <input
                    type="text"
                    name="lastname"
                    placeholder="Enter your last name..."
                    required
                />
            </label>
            <label>
                Company
                <input
                    type="text"
                    name="company"
                    placeholder="Enter your company..."
                    required
                />
            </label>
            <label htmlFor="email">
                Work Email
                <input
                    type="email"
                    name="email"
                    placeholder="Enter your work email..."
                    required
                />
            </label>
            <label htmlFor="message">
                Message (max 250 characters) optional
                <textarea
                    rows="5"
                    name="message"
                    placeholder="Be as detailed as possible..."
                    maxLength="250"
                />
            </label>
            <Recaptcha
                sitekey="6LfHMa8UAAAAAEPO3542EyD3phNUmx3BL2AEj20Q"
                verifyCallback={verifyCallback}
                theme="dark"
            />
            <button type="submit" className="submit-btn">
                Submit
            </button>
            <button onClick={e => closeForm(e)} className="close-form-btn">
                Close
            </button>
        </form>
    )
}

export default Form
