import React from "react"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
    const { t } = useTranslation()

    return (
        <footer className="footer">
            <div className="sponsors">
                <img
                    className="sponsor-partner"
                    src="/assets/aws-partner.png"
                    alt="AWS partner icon"
                />
                <img
                    className="sponsor-partner"
                    src="/assets/Microsoft-partner.png"
                    alt="Microsoft partner icon"
                />
                <img
                    className="sponsor-partner"
                    src="/assets/Bitdefender.png"
                    alt="Bitdefender icon"
                />
            </div>
            <div className="footer-info">
                <span>Copyright &copy; {new Date().getFullYear()}</span>
                <span>
                    <NavLink
                        to="/privacy-policy"
                        className="privacyPolicy-link"
                        activeClassName="active-privacy"
                    >
                        {t("privacyPolicy")}
                    </NavLink>
                </span>
                <a
                    href="https://www.linkedin.com/company/esper-technologies-hk/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FontAwesomeIcon
                        icon={faLinkedin}
                        style={{ color: "white" }}
                        size="lg"
                    />
                </a>
            </div>
        </footer>
    )
}

export default Footer
