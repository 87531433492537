import React from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons"

const FormSubmitted = () => {
    const { t } = useTranslation()

    return (
        <div className="formSubmitted">
            <Helmet>
                <title>Espertech - Form Submitted!</title>
                <meta
                    name="description"
                    content="You have successfully submitted your form."
                />
            </Helmet>
            <h1 className="form-header">{t("form.submitted")}</h1>
            <button>
                <NavLink to="/" exact>
                    <FontAwesomeIcon icon={faAngleLeft} size="sm" />
                    <span className="error-button-text">
                        {t("error.returnHome")}
                    </span>
                </NavLink>
            </button>
        </div>
    )
}

export default FormSubmitted
