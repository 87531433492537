import React, { useState, createContext } from "react"
import { useTranslation } from "react-i18next"

export const AppProvider = createContext()

export const Provider = ({ children }) => {
    const { i18n } = useTranslation()

    const [cookie, setCookie] = useState(false)
    const [language, setLanguage] = useState("ENGLISH")
    const [langMenu, setLangMenu] = useState(false)
    const [jobsVisibility, setJobsVisibility] = useState(false)
    const [aboutUsVisibility, setAboutUsVisibility] = useState(false)
    const [formVisibility, setFormVisibility] = useState(false)

    // Cookies
    const checkCookieInLS = () =>
        localStorage.getItem("Cookies") ? setCookie(true) : setCookie(false)

    const addCookieToLS = () => {
        if (!cookie) {
            localStorage.setItem("Cookies", "true")
            setCookie(true)
        }
    }

    // Language changer
    const languageRef = React.useRef(null)
    const langMenuRef = React.useRef(null)

    const languages = { en: "English", cn: "繁體中文" }

    const changeLanguage = lang => {
        setLanguage(languages[lang])
        i18n.changeLanguage(lang)
    }

    const toggleLangMenu = () => setLangMenu(prevLang => !prevLang)

    // Nav
    const jobsRef = React.useRef(null)
    const aboutUsRef = React.useRef(null)

    const navModuleChanger = tab => {
        switch (tab) {
            case "TOGGLE_JOBS":
                return setJobsVisibility(prevJobVisi => !prevJobVisi)
            case "TOGGLE_ABOUT_US":
                return setAboutUsVisibility(prevAboutUsVisi => !prevAboutUsVisi)
            case "CLOSE_JOBS":
                return setJobsVisibility(false)
            case "CLOSE_ABOUT_US":
                return setAboutUsVisibility(false)
            default:
                return
        }
    }

    // Consultation form
    const formRef = React.useRef(null)

    const toggleFormVisibility = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        setFormVisibility(prevForm => !prevForm)
    }

    const closeForm = e => {
        e.preventDefault()
        setFormVisibility(false)
    }

    // Global state object
    const value = {
        cookie,
        setCookie,
        language,
        setLanguage,
        langMenu,
        setLangMenu,
        jobsVisibility,
        setJobsVisibility,
        aboutUsVisibility,
        setAboutUsVisibility,
        formVisibility,
        setFormVisibility,
        checkCookieInLS,
        addCookieToLS,
        languageRef,
        langMenuRef,
        changeLanguage,
        toggleLangMenu,
        jobsRef,
        aboutUsRef,
        navModuleChanger,
        formRef,
        toggleFormVisibility,
        closeForm
    }

    return <AppProvider.Provider value={value}>{children}</AppProvider.Provider>
}
