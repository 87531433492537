import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import XHR from "i18next-xhr-backend"

import eng from "../locales/en/translation.json"
import cn from "../locales/cn/translation.json"

const findLanguage = () => {
    const lang = localStorage.getItem("i18nextLng")
    if (lang) {
        return lang
    } else if (!lang) {
        localStorage.setItem("i18nextLng", "en")
    }
}

i18n.use(XHR)
    .use(LanguageDetector)
    .init({
        lng: findLanguage(),
        fallbackLng: "en",
        returnObjects: true,
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: {
                translations: eng
            },
            cn: {
                translations: cn
            }
        },
        ns: ["translations"],
        defaultNS: "translations"
        // debug: true, <--- console.log translation changes
        // keySeparator: "false", <--- set false to prohibit nested translation objects
    })

export default i18n
