import React from "react"
import { Switch, Route } from "react-router-dom"

import "./css/style.css"

import Layout from "./components/Layout"
import Homepage from "./components/pages/Homepage"
import PrivacyPolicy from "./components/pages/PrivacyPolicy"
import FormSubmitted from "./components/pages/FormSubmitted"
import Error from "./components/pages/Error"

const App = () => {
    return (
        <main className="app">
            <Layout>
                <Switch>
                    <Route path="/" component={Homepage} exact />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/form-submitted" component={FormSubmitted} />
                    <Route component={Error} />
                </Switch>
            </Layout>
        </main>
    )
}

export default App
