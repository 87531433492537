import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"

import { ReactComponent as CloseButton } from "../shared/x.svg"
import { AppProvider } from "../../AppProvider"

const AboutUs = () => {
    const { t } = useTranslation()

    const { aboutUsRef, aboutUsVisibility, navModuleChanger } = useContext(
        AppProvider
    )

    return (
        <aside
            className={classNames({
                aboutUs: true,
                "aboutUs-open": aboutUsVisibility
            })}
            ref={aboutUsRef}
        >
            <div className="aboutUs-header">
                <h1 className="aboutUs-title">{t("team")}</h1>
                <div
                    className="close-btn"
                    onClick={() => navModuleChanger("CLOSE_ABOUT_US")}
                >
                    <CloseButton />
                </div>
            </div>
            <div className="founders">
                <div className="founder">
                    <img src="/assets/Jean.png" alt="Cofounder 1" />
                    <h1>Jean-Baptiste Cebe</h1>
                    <h3>Lead Advisor</h3>
                    <a
                        href="https://hk.linkedin.com/in/jbcebe"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon
                            icon={faLinkedin}
                            style={{ color: "white" }}
                            size="lg"
                        />
                    </a>
                    <p>
                        Mr. Cebe is the Chief Technology Officer for Seekers
                        Capital Group. He is responsible for the group’s trading
                        technology solutions, infrastructure and cybersecurity
                        since 2011. He has been providing leadership to the
                        software development efforts and its 20 engineers.
                    </p>
                </div>

                <div className="founder">
                    <img src="/assets/Vladmir.png" alt="Cofounder 2" />
                    <h1>Vladimir Karpov</h1>
                    <h3>Senior Systems Administrator</h3>
                    <a
                        href="https://www.linkedin.com/in/vladimir-karpov-9704b718b/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon
                            icon={faLinkedin}
                            style={{ color: "white" }}
                            size="lg"
                        />
                    </a>
                    <p>
                        During his 15-years career Mr. Karpov has worked at
                        multiple IT Services companies and was involved in
                        infrastructure projects for Haitong Securities, BNY
                        Mellon, Credit Suisse.
                    </p>
                </div>
            </div>
        </aside>
    )
}

export default AboutUs
