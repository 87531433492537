import React from "react"
import ReactDOM from "react-dom"
import { I18nextProvider } from "react-i18next"
import { BrowserRouter, Route } from "react-router-dom"

import { Provider } from "./AppProvider"
import i18n from "./components/i18n"
import App from "./App"

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider>
            <BrowserRouter>
                <Route path="/" component={App} />
            </BrowserRouter>
        </Provider>
    </I18nextProvider>,
    document.getElementById("root")
)
