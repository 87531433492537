import React, { Fragment } from "react"
import { Helmet } from "react-helmet"

import PrivacyText from "../privacyPolicy/PrivacyText"

const PrivacyPolicy = () => {
    React.useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [])

    return (
        <Fragment>
            <Helmet>
                <title>Espertech - Privacy Policy</title>
                <meta
                    name="description"
                    content="The Espertech Privacy Policy covers how we collect, use, disclose, transfer, and store your personal information."
                />
            </Helmet>
            <PrivacyText />
        </Fragment>
    )
}

export default PrivacyPolicy
